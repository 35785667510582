import { addLoader, removeLoader } from './components/loading-dots';

/**
 * Update calendar content
 * @param {String} source
 * @param {String} lastItem
 * @returns
 */
const updateCalendar = (source, wrapper) => {
    const url = new URL(window.location.origin + source);
    const xhr = new XMLHttpRequest();

    addLoader(wrapper, { inner: true });
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = () => {
        if (xhr.status === 200) {
            wrapper.innerHTML = xhr.response.content;
            removeLoader();
        }
    };
    xhr.send();
};

/**
 * Handles click on load more button
 * @param {Element} button
 * @returns
 */
const handleButtonClick = (button) => {
    if (button.getAttribute('data-load-more-content') !== 'true') {
        return;
    } else {
        const source = button.getAttribute('data-source');
        const wrapper = document.getElementById(button.getAttribute('aria-controls'));
        if (source && wrapper) {
            updateCalendar(source, wrapper);
        }
    }
};

// Handle button click
document.addEventListener('click', (e) => {
    if (e.target.matches('[aria-controls="calendar-wrapper"]')) handleButtonClick(e.target);
});
