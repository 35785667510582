const loadingDots = document.createElement('div');
loadingDots.style.cssText = 'display: block; width:100%; text-align: center;';
loadingDots.innerHTML = '<div class="loading-dots"><div></div><div></div><div></div><div></div></div>';

/**
 * Add loader to wrapper
 * @param {Element} wrapper
 * @param {Object} options
 */
const addLoader = (wrapper, options = {}) => {
    // append into wrapper
    if (options.inner) {
        wrapper.append(loadingDots);
    } else {
        // append after wrapper
        wrapper.parentNode.insertBefore(loadingDots, wrapper.nextSibling);
    }
};

/**
 * Removes loader
 */
const removeLoader = () => {
    document.querySelector('.loading-dots').remove();
};

export { addLoader, removeLoader };
